body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"!important;
    font-size: 1rem!important;
    font-weight: 400!important;
    line-height: 1.5!important;
    -webkit-font-smoothing: auto;
    background: rgb(37, 42, 75);
    color: #fff;
}

.fade.in {
    opacity: 1!important;
}

p+p {
    margin: 0;
}

.p-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.connection, .connection>div {
    display: flex;
    flex-direction: column;
}

.connection {
    position: absolute;
    justify-content: space-evenly;
    min-width: 280px;
    width: 30vw;
    max-width: 380px;
    border-radius: 0.5em;
    padding: 2em;
    background: #fff;
    color: black;
}

header {
    position: sticky;
    top: 0;
    height: 4rem;
    padding: 0 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1001;
    background: rgb(37, 42, 75);
}

main {
    width: 100%;
}

a, a:hover, a:focus {
    text-decoration: none!important;
    color: #fff!important;
}

header a {
    border: 1px solid #fff;
    padding: 0.5em;
    border-radius: 0.75em;
    transition: transform ease 0.5s;
}

header a:hover {
    transform: translate(0, -5px);
}

.left-navbar {
    display: flex;
    flex-direction: column;
    width: 20vw;
    min-width: 235px;
    background: rgb(32, 36, 65);
    border-top-right-radius: 2em;
    padding: 4em 0 0 2em;
    position: sticky;
    top: 4rem;
    z-index: 1000;
    height: calc(100vh - 4rem);
}

.left-navbar>* {
    padding: 1em 0;
    display: flex;
    align-items: center;
}

.left-navbar svg {
    width: 28px;
    fill: rgb(74, 77, 114);
    margin-right: 1em;
}

.left-navbar, .left-navbar a, .left-navbar a:hover {
    color: rgb(74, 77, 114)!important;
    text-decoration: none;
}

.left-navbar .active, .left-navbar .active:hover {
    position: relative;
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    padding: 1em;
    margin-left: -1em;
    color: #fff!important;
    background: linear-gradient(90deg, rgba(37, 42, 75, 0), rgb(37, 42, 75));
}

.left-navbar .active svg {
    fill: #fff;
}

.left-navbar .active::before {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: 60px;
    height: 50px;
    width: 25px;
    margin-left: calc(100% - 25px - 1em);
    border-bottom-right-radius: 25px;
    box-shadow: 0 25px 0 0 rgb(37, 42, 75);
}

.left-navbar .active::after {
    content: "";
    position: absolute;
    display: block;
    background-color: transparent;
    bottom: -50px;
    height: 50px;
    width: 25px;
    margin-left: calc(100% - 25px - 1em);
    border-top-right-radius: 25px;
    box-shadow: 0 -25px 0 0 rgb(37, 42, 75);
}

.disconnect-button, .disconnect-button:focus {
    background: none;
    text-align: left;
    outline: none;
}

#Page {
    padding: 3em;
    padding-top: 0;
    width: 100%;
}

#Page h1 {
    margin-bottom: 0.5em;
}

.material-card {
    background: rgb(46, 51, 89);
    padding: 1em;
    border-radius: 1em;
    display: flex;
}

.set-date-button {
    background: rgb(74, 77, 114);
    border-radius: 0.5em;
    color: #fff;
}

.set-date-button:hover {
    color: #fff;
}

.set-card {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: rgb(32, 36, 65);
    padding: 2em;
    z-index: 1002;
}

.set-card>*, .rs-picker-menu {
    z-index: 1003;
}

.rs-picker-menu {
    color: initial;
}

.rs-tag-text {
    font-size: initial;
}

.set-card.date>span {
    margin: 1rem 0;
}

.set-card.rdv {
    min-width: 300px;
    width: 30vw;
}

.set-card button {
    width: -webkit-fill-available;
}

.close-icon {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
}

.rs-calendar-time-dropdown-cell, .rs-calendar-time-dropdown-cell:hover, .rs-tag-text * {
    color: initial!important;
}

.appointments, .undefinedAppointment {
    justify-content: space-between;
}

.appointments>div, .undefinedAppointment {
    align-items: center;
}

.undefinedAppointment {
    display: flex;
    width: 100%;
}

.server-information {
    flex-direction: column;
    align-items: center;
    padding: 2em;
}

.chart {
    padding: 0;
    margin: 0;
    width: 380px;
    overflow: hidden;
}

.chart>div {
    margin-left: -10px;
    margin-bottom: -20px;
    padding: 0;
}

.rs-calendar-table-cell, .rs-picker-toolbar-option, .rs-picker-toolbar-option:hover, .rs-dropdown-toggle, .rs-dropdown-toggle:focus, .rs-dropdown-toggle:hover, .rs-dropdown-item-content, .rs-dropdown-item-content:hover {
    color: black!important;
}

.date-chart>div {
    width: 100%;
}

.client-informations {
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.client-informations>* {
    margin-bottom: 1em;
}

.client-informations *:last-child {
    margin-bottom: 0;
}

#client p:hover::after {
    content: "";
    display: inline-block;
    background: no-repeat url('../assets/icons/edit-2.svg');
    width: 17px;
    height: 17px;
    margin-left: 1em;
    cursor: pointer;
}

.client {
    max-width: 500px;
}

.client form {
    display: flex;
    flex-direction: column;
}

.client span p {
    width: 6em
}

.client input, .client input:hover {
    margin-left: 5px;
}

.menu-icon {
    cursor: pointer;
}

.server-chart {
    display: flex;
    align-items: center;
}

.form-panel, .form-panel-select {
    align-items: center;
    margin-bottom: 1em;
}

.form-panel>span, .form-panel-select {
    display: flex;
}

.form-panel>span {
    width: -webkit-fill-available;
}

.form-panel span>div {
    width: inherit;
}

.form-panel span>div:first-child {
    margin-right: 1em;
}

.form-panel span>div:last-child {
    margin-left: 1em;
}

.form-panel>span, .form-panel>div:first-child {
    margin-bottom: 1em;
}

.form-panel-select {
    flex-direction: column;
}

.form-panel-select>div {
    margin-top: 1em;
}

.form-panel-select>div:first-child {
    margin-top: 0;
}

.rs-picker-select-menu-item {
    color: black!important;
}

.contact-card {
    flex-direction: column;
    padding: 1em 1.5em;
}

.contact-card>* {
    margin-bottom: 0.5em;
}

.delete-confirmation {
    background: #fff;
    color: black;
}

.toast {
    backdrop-filter: initial
}

.emailExist {
    color: #1675e0;
    cursor: pointer;
    display: inline-block;
}

.action-button {
    cursor: pointer;
    width: 40px;
    transition: 0.3s ease width, 1s ease stroke, 1s ease transform;
}

.server .action-button:hover {
    width: 45px;
}

.poweron {
    stroke: #4CAF50
}

.restart:hover {
    transform: rotate(360deg);
}

.apexcharts-menu-item {
    color: initial;
}

.delete-card {
    overflow: hidden;
}

.delete-icon {
    height: auto;
    width: 0;
    padding: 0;
    background: #E64A19;
    cursor: pointer;
    transition: width 0.5s ease
}

#link{
    color: #1675e0!important;
}

#link:hover{
    text-decoration: underline!important;
}

/*Ordinateur*/

@media (min-width: 993px) {
    main{
        padding: 0 3rem;
    }
    .menu-icon {
        display: none;
    }
    .appointments>div {
        display: flex;
    }
    .appointments p:first-child, .undefinedAppointment p:first-child {
        margin-right: 5em!important;
    }
    .type-item {
        border-right: 1px solid #fff;
        margin-right: 2em;
        padding-right: 2em;
    }
    .server-chart {
        justify-content: space-around;
    }
    .server-chart>* {
        margin-right: 1em;
    }
    .server-chart>*:last-child {
        margin-right: 0;
    }
    .pm2-informations {
        display: flex;
        justify-content: space-around;
    }
    .pm2-informations>* {
        margin-right: 2em;
    }
    .contact-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, 350px);
        grid-gap: 3em;
    }
    .delete-card {
        overflow: hidden;
    }
    .delete-icon {
        height: auto;
        width: 0;
        padding: 0;
        background: #E64A19;
        cursor: pointer;
        transition: width 0.5s ease
    }
    .delete-card:hover .delete-icon {
        width: 40px;
    }
}

/*Portable*/

@media (max-width: 992px) {
    main {
        text-align: center;
        padding: 1em;
    }
    header {
        position: sticky;
    }
    .left-navbar {
        position: fixed;
        transform: translate(-100%, 0);
        transition: all ease 0.5s;
    }
    .left-navbar-transition {
        transform: translate(0%, 0);
    }
    .appointments, .undefinedAppointment, .undefinedAppointment div {
        flex-direction: column;
        text-align: center;
    }
    .appointments>div>*, .undefinedAppointment div>* {
        margin-bottom: 0.5em!important;
    }
    .server-chart {
        flex-direction: column;
    }
    .server-chart>*, .pm2-informations>* {
        margin-bottom: 2em;
    }
    .contact-container>* {
        margin-bottom: 2em;
    }
    .contact-container>*:last-child {
        margin: 0;
    }
    .delete-card .delete-icon {
        width: 40px;
    }
}